// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-y: scroll;
}

div#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App nav {
  padding: 0;
}

.label.nomargin {
  margin-left: 0;
}

input[type=range]::-webkit-slider-thumb {
  cursor: ew-resize;
}

@media (prefers-color-scheme: dark) {
  nav, body, .modal .overlay ~ *, .card {
    background: #222 !important;
    color: #fff !important;
  }
  .modal .overlay ~ *, .card {
    border-color: #555 !important;
  }
  pre {
    background: #333 !important;
  }
  select {
    background: #222 !important;
  }
  input, textarea {
    background-color: #222 !important;
  }
  article.collapsible.noborder {
    background: #333 !important;
  }
  .loader:before,
  .loader:after {
    border: 2px solid #aaa;
  }
  [type=checkbox]:checked + .checkable:after {
    color: #eee !important;
  }
  .ReactModal__Overlay {
    background: rgba(30, 30, 30, 0.75) !important;
  }
  .ReactModal__Content {
    background: #222 !important;
    border-color: #888 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;EACA,YAAA;EACA,kBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,+EAAA;AADF;;AAKA;EACE,UAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE,iBAAA;AAFF;;AAKA;EACE;IACE,2BAAA;IACA,sBAAA;EAFF;EAKA;IACE,6BAAA;EAHF;EAMA;IACE,2BAAA;EAJF;EAOA;IACE,2BAAA;EALF;EAQA;IACE,iCAAA;EANF;EASA;IACE,2BAAA;EAPF;EAUA;;IAEE,sBAAA;EARF;EAWA;IACE,sBAAA;EATF;EAYA;IACE,6CAAA;EAVF;EAaA;IACE,2BAAA;IACA,6BAAA;EAXF;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n  sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  height: 100%;\n  overflow-y: scroll;\n}\n\ndiv#root {\n  height: 100%;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n  monospace;\n}\n\n.App nav {\n  padding: 0;\n}\n\n.label.nomargin {\n  margin-left: 0;\n}\n\ninput[type=\"range\"]::-webkit-slider-thumb {\n  cursor: ew-resize;\n}\n\n@media (prefers-color-scheme: dark) {\n  nav, body, .modal .overlay ~ *, .card {\n    background: #222 !important;\n    color: #fff !important;\n  }\n\n  .modal .overlay ~ *, .card {\n    border-color: #555 !important;\n  }\n\n  pre {\n    background: #333 !important;\n  }\n\n  select {\n    background: #222 !important;\n  }\n\n  input, textarea {\n    background-color: #222 !important;\n  }\n\n  article.collapsible.noborder {\n    background: #333 !important;\n  }\n\n  .loader:before,\n  .loader:after {\n    border: 2px solid #aaa;\n  }\n\n  [type=checkbox]:checked + .checkable:after {\n    color: #eee !important;\n  }\n\n  .ReactModal__Overlay {\n    background: rgba(30, 30, 30, 0.75) !important;\n  }\n\n  .ReactModal__Content {\n    background: #222 !important;\n    border-color: #888 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
