// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
article.collapsible header {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}
article.collapsible header > div {
  margin-right: 1em;
}
article.collapsible header::after {
  float: right;
  content: "⌃";
  font-weight: bold;
  font-size: 1.2em;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
article.collapsible section, article.collapsible footer {
  padding-left: 4px;
  padding-right: 4px;
}
article.collapsible.noborder {
  border-left: none;
  border-right: none;
  background: #eee;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
article.collapsible.noborder > section, article.collapsible.noborder > footer {
  padding-left: 0;
  padding-right: 0;
}

article.collapsed header::after {
  content: "⌄";
}
article.collapsed section,
article.collapsed footer {
  display: none;
}

article.inverse-icon.collapsible header::after {
  content: "⌄";
}

article.inverse-icon.collapsed header::after {
  content: "⌃";
}`, "",{"version":3,"sources":["webpack://./src/components/CollapsibleCard.scss"],"names":[],"mappings":"AAAA,gBAAgB;AACd;EACE,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,kBAAA;AACJ;AACI;EACE,iBAAA;AACN;AAGE;EACE,YAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,QAAA;EACA,2BAAA;AADJ;AAIE;EACE,iBAAA;EACA,kBAAA;AAFJ;AAKE;EACE,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,qBAAA;AAHJ;AAKI;EACE,eAAA;EACA,gBAAA;AAHN;;AASE;EACE,YAAA;AANJ;AASE;;EAEE,aAAA;AAPJ;;AAWA;EACE,YAAA;AARF;;AAWA;EACE,YAAA;AARF","sourcesContent":["article.collapsible {\n  header {\n    cursor: pointer;\n    user-select: none;\n    position: relative;\n\n    > div {\n      margin-right: 1em;\n    }\n  }\n\n  header::after {\n    float: right;\n    content: '⌃';\n    font-weight: bold;\n    font-size: 1.2em;\n    position: absolute;\n    right: 0.5rem;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n  section, footer {\n    padding-left: 4px;\n    padding-right: 4px;\n  }\n\n  &.noborder {\n    border-left: none;\n    border-right: none;\n    background: #eee;\n    padding-left: 0.4rem;\n    padding-right: 0.4rem;\n\n    > section, > footer {\n      padding-left: 0;\n      padding-right: 0;\n    }\n  }\n}\n\narticle.collapsed {\n  header::after {\n    content: '⌄';\n  }\n\n  section,\n  footer {\n    display: none;\n  }\n}\n\narticle.inverse-icon.collapsible header::after {\n  content: '⌄';\n}\n\narticle.inverse-icon.collapsed header::after {\n  content: '⌃';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
